function chatbotButton() {
  $( ".chatbot-toggle" ).click(function() {
    $( ".chatbot-widget" ).toggleClass("chat-disabled");
    appendNewMessages();
    // Toggle text on button
    let button = $('#chatbot-toggle-button');
    if ($(button).text() == 'Close') {
      $(button).text('Ask Kevin');
    } else {
      $(button).text('Close');
      scrollToBottom();
    }
  });

  // Send message on enter key press
  $('#message-input').keypress(function(event) {
    if (event.which === 13) { // Enter key code
      event.preventDefault();
      sendMessage();
    }
  });
}

function getMessages(callback) {
  $.ajax({
    url: '/chatbot/messages',
    type: 'GET',
    dataType: 'json',
    success: function(data) {
      callback(data);
    },
    error: function(xhr, status, error) {
      console.error('Error fetching messages:', error);
    }
  });
}

function parseMarkdown(markdown) {
  let html = markdown
    .replace(/```([\s\S]*?)```/gim, '<pre><code class="code">$1</code></pre>')
    .replace(/^###### (.*$)/gim, '<h6 class="title is-6">$1</h6>')
    .replace(/^##### (.*$)/gim, '<h5 class="title is-5">$1</h5>')
    .replace(/^#### (.*$)/gim, '<h4 class="title is-4">$1</h4>')
    .replace(/^### (.*$)/gim, '<h3 class="title is-3">$1</h3>')
    .replace(/^## (.*$)/gim, '<h2 class="title is-2">$1</h2>')
    .replace(/^# (.*$)/gim, '<h1 class="title is-1">$1</h1>')
    .replace(/\*\*(.*)\*\*/gim, '<strong>$1</strong>')
    .replace(/\*(.*)\*/gim, '<em>$1</em>')
    .replace(/\[(.*?)\]\((.*?)\)/gim, '<a href="$2">$1</a>')
    .replace(/\n$/gim, '<br />');

  return html.trim();
}

function appendNewMessages() {
  getMessages(function(data) {
    $.each(data, function(index, message) {
      let role = message["role"] === "user" ? "chatbot-message-incoming" : "chatbot-message-outgoing";
      let content = message["content"] && message["content"][0] && message["content"][0]["text"] ? message["content"][0]["text"]["value"] : "No content, please try again later.";

      let timestamp = new Date(message["created_at"] * 1000).toLocaleString();
      let newMessage = `
          <div class='chatbot-message ${role}' data-id='${message.id}'>
            <div class="chatbot-message-bubble">
              <p class="chatbot-message-text">${parseMarkdown(content)}</p>
              <span class="chatbot-message-timestamp">${timestamp}</span>
            </div>
          </div>
        `;
      $('.chatbot-messages').append(newMessage);
    });
    scrollToBottom();
  });
}

function scrollToBottom() {
  $('.chatbot-messages').scrollTop($('.chatbot-messages')[0].scrollHeight);
}

function checkStatus() {
  $.ajax({
    url: '/chatbot/check_run_status',
    type: 'GET',
    success: function(response) {
      if (response['status'] === 'completed') {
        appendNewMessages();
      } else if (response['status'] === 'wait') {
        setTimeout(function() {
          checkStatus();
        }, 1000);
      } else {
        console.error(response['status'], response['error']);
      }
    },
    error: function(data) {
      alert("Error: " + data);
    }
  });
}

window.sendMessage = function() {
  let message = $("#message-input").val();

  // Show "Typing..." placeholder
  let typingPlaceholder = `
    <div class="chatbot-message-bubble" id="chatbot-message-typing">
      <p class="help has-text-centered">Typing...</p>
    </div>
  `;
  $('.chatbot-messages').append(typingPlaceholder);
  scrollToBottom();

  $.ajax({
    url: '/chatbot',
    type: 'POST',
    data: {
      message: message
    },
    success: function(response) {
      $("#message-input").val("");
      checkStatus();
    },
    error: function(data) {
      alert("Error: " + data);
    }
  });
}

document.addEventListener("turbolinks:load", function() {
  chatbotButton();
})

